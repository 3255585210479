/*
 *  ======================================= 
 *  CMS | _cms
 *  =======================================  
 */

// Style pod NEW CMS - LEMON IT

/* GALERIA */

.gallery {
    a {
        display: block;
        position: relative;
        margin: 15px 0;
        overflow: hidden;

        &:before {
            @include after_overlay(rgba(0, 0, 0, 0));
            transition: background .3s ease;
        }

        &:hover, &:focus {
            &:before {
                background: rgba(0, 0, 0, 0.3);
                transition: background .3s ease;
            }

            .gallery-image {
                transform: scale(1.2);
                transition: transform .3s ease;
            }

            i {
                color: rgba(255, 255, 255, 1);
                top: 50%;
                transition: all .3s ease .2s;
            }

            .gallery-title {
                opacity: 0;
                transition: opacity .15s ease;
            }
        }
    }

    &-image {
        position: absolute;
        background-position: center center;
        background-size: cover;
        height: 100%;
        width: 100%;
        transition: transform .3s ease;
        top: 0;
        left: 0;
    }

    &-ratio {
        position: relative;
        padding-bottom: 70%;
    }

    i {
        color: rgba(255, 255, 255, 0);
        font-size: 40px;
        position: absolute;
        top: 60%;
        transform: translateY(-50%);
        transition: all .3s ease;
        width: 100%;
        text-align: center;
        z-index: 2;
    }

    &-title {
        color: white;
        font-size: 18px;
        position: absolute;
        text-shadow: 0 0 5px black;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity .15s ease;
        width: 100%;
        text-align: center;
        z-index: 2;
    }
}

/* LEADY */

.leads {
    &-row {
        &:after {
            content: '';
            position: relative;
            background: #cbcbcb;
            bottom: 0;
            left: 15px;
            height: 1px;
            width: calc(100% - 15px);
        }

        &:last-child:after {
            display: none;
        }
    }

    &-image {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 60%;
        width: 100%;
        display: block;
        background-position: center center;
        background-size: cover;
        position: relative;

        &:after {
            @include after_overlay(rgba(51, 51, 51, 0));
            transition: background .3s ease;
        }

        &:hover, &:focus, &:active {
            &:after {
                background: rgba(51, 51, 51, 0.3);
                transition: background .3s ease;
            }

            i {
                color: rgba(255, 255, 255, 1);
                transition: color .3s ease .15s;
            }
        }

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            color: rgba(255, 255, 255, 0);
            font-size: 40px;
            transform: translate(-50%, -50%);
            transition: color .3s ease;
            z-index: 2;
        }
    }

    &-title {
    }

    &-date {
        color: #999;
        display: block;
        margin-bottom: 20px;
    }

    &-content {
        margin-bottom: 20px;
        text-align: justify;
    }

    &-more {
        margin-bottom: 20px;
        text-align: right;
    }

    &-image-details {
        max-width: 50%;
    }
}

/* ALERT */

.alert {
    border-radius: 0;
    padding: 10.5px 20px;
    margin-top: 20px;

    ul {
        margin-bottom: 0 !important;
        padding-left: 0;
        list-style-type: none;
        text-align: center;
        font-weight: 700;

        li {
            margin-bottom: 0 !important;
            padding-left: 0 !important;

            &:before {
                display: none;
            }
        }
    }
}

/* INFORMACJA O COOKIES */

#cookie_info_bar {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 300px;
    background: #e1e1e1;
    padding: 30px 15px 15px;
    font-size: 12px;
    color: black;
    text-align: justify;
    z-index: 999;

    p {
        margin-bottom: 0;
    }

    a {
        background-color: transparent;
        color: grey;

        &:hover {
            color: darkgrey;
            text-decoration: none;
        }
    }

    > div {
        position: relative;
    }
}

#close_cookie_info_bar {
    color: black;
    position: absolute;
    right: 10px;
    top: -6px;
    background: none;
    border: none;
    font-size: 28px;
    transition: all .3s ease;
    padding: 0;

    &:hover, &:focus {
        color: grey;
        text-decoration: none;
        transition: all .3s ease;
        outline: none !important;
        box-shadow: unset !important;
    }
}